import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <AppContent />
    </div>
  );
}

class AppContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { passwordcorrect: false };

    // this is necessary to keep the context in this component when passing to children (PasswordInput)
    this.passwordSuccess = this.passwordSuccess.bind(this);
  }

  passwordSuccess() {
    this.setState({ passwordcorrect: true });
  }

  render() {
    switch (this.state.passwordcorrect) {
      case true:
        return (
          <ProtectedContent />
        );

      default:
        return (
          <PasswordInput passwordSuccess={this.passwordSuccess} />
        );
    }
  }
}

class ProtectedContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: '' };
    
    this.handleSearchText = this.handleSearchText.bind(this);
  }

  handleSearchText(event) {
    this.setState({
      value: event.target.value
    });
  }

  render() {
    return (
      <div className="ProtectedStyling">
        <p>We're in</p>
        <input type="text"
          style={{width: "50vw"}}
          className="TextInput"
          value={this.state.value}
          placeholder="search away..."
          onChange={this.handleSearchText} />
      </div>
    );
  }

}

class PasswordInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: '' };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
    switch (event.target.value) {
      case "asdf":
        this.props.passwordSuccess();
        break;

      default:
        break;
    }
  }

  render() {
    return (
      <div>
        <p>
          Hi
        </p>
        <input type="text" className="TextInput" value={this.state.value} onChange={this.handleChange} />
      </div>
    );
  }
}

export default App;

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

/* <form>
  <label>
    <input type="text" name="name" />
  </label>
  <input type="submit" value="Submit" />
</form> */